<template>
  <v-dialog
    @click:outside="closeDialog"
    :value="dialog"
    max-width="650px"
    eager
  >
    <v-card class="pb-2">
      <v-card-title class="text-capitalize">
        Block {{ block.title }}
      </v-card-title>
      <v-card-text>
        <v-simple-table
          class="mb-2 block-newfield-table"
          v-if="block.allowAddFields"
        >
          <template v-slot:default>
            <tbody>
              <tr>
                <td class="text-left" style="padding: 0px">
                  <v-text-field
                    v-model="newFieldName"
                    dense
                    outlined
                    hide-details
                    placeholder="New field name..."
                    style="width: 260px"
                    class="float-left"
                  />
                  <v-btn @click="addNewField" class="pl-5 pr-6 ml-4">
                    <v-icon small class="mr-2 float-left">mdi-plus</v-icon>
                    Add
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-simple-table class="block-edit-table">
          <template v-slot:default>
            <thead class="grey lighten-4">
              <tr>
                <th class="text-left">
                  <h3 class="black--text">Field</h3>
                </th>
                <th class="text-left">
                  <h3 class="black--text">Default Value</h3>
                </th>
                <th class="text-left">
                  <h3 class="black--text">Nullable</h3>
                </th>
                <th>
                  <h3 class="black--text">Action</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(field, index) in block.fields.filter(
                  (b) => b.attr == 'input'
                )"
                :key="index"
              >
                <td>{{ field.name }}</td>
                <td>
                  <v-text-field
                    v-if="field.editable"
                    v-model="field.defaultValue"
                    dense
                    filled
                    hide-details
                    placeholder="auto"
                    style="width: 210px"
                  />
                  <span v-else>{{ field.defaultValue || "auto" }}</span>
                </td>
                <td>
                  <v-checkbox
                    v-if="field.addedByUser || field.editable"
                    v-model="field.allowNull"
                  ></v-checkbox>
                  <span v-else><v-icon>mdi-check</v-icon></span>
                </td>
                <td>
                  <v-btn
                    icon
                    v-if="field.addedByUser"
                    @click="removeField(field.name)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions style="margin-top: -10px">
        <v-spacer></v-spacer>
        <v-btn @click.stop="saveConfig" class="primary mr-2 pl-5 pr-6">
          Save configs
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <DialogConfirm
      ref="DialogConfirm"
      :message="'Cannot add duplicated field!'"
    />
  </v-dialog>
</template>

<script>
import DialogConfirm from "@/components/DialogConfirm";

export default {
  components: {
    DialogConfirm,
  },
  data() {
    return {
      dialog: false,
      block: {
        fields: [],
      },
      newFieldName: "",
    };
  },
  methods: {
    open(block = {}) {
      this.resetComponent();
      this.block = block;
      this.dialog = true;
    },
    async addNewField() {
      if (this.block.fields.find((f) => f.name == this.newFieldName)) {
        return await this.$refs.DialogConfirm.confirm();
      }
      var field = JSON.parse(
        JSON.stringify(this.block.fields.find((f) => f.attr == "input"))
      );
      field.name = this.newFieldName;
      field.addedByUser = true;
      this.block.fields.push(field);
      this.newFieldName = "";
    },
    removeField(fieldname) {
      var ind = this.block.fields.findIndex((f) => f.name == fieldname);
      if (ind == -1) return;
      this.block.fields.splice(ind, 1);
    },
    async saveConfig() {
      this.$emit("onBlockEdit", this.block);
      this.closeDialog();
    },
    resetComponent() {
      this.newFieldName = "";
      this.block = {
        fields: [],
      };
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss">
.h-4 {
  height: 40px !important;
}
.lh-4 {
  line-height: 40px !important;
}
.block-edit-table {
  border: 1px solid #e0e0e0;
  th {
    border-right: 1px solid #e0e0e0;
    font-size: 0.85rem !important;
  }
  td {
    border-right: 1px solid #e0e0e0;
    font-size: 0.95rem !important;
  }
  th:last-child {
    border-right: none;
  }
  td:last-child {
    border-right: none;
  }
}
.block-newfield-table {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>