<template>
  <div class="pt-4">
    <PopupBlockEditor
      ref="PopupBlockEditor"
      @onBlockEdit="handleBlockChanged"
    />
    <PopupOutputEditor
      ref="PopupOutputEditor"
      @onBlockEdit="handleBlockChanged"
    />
    <v-row class="pl-2 pr-2 pt-2">
      <v-col cols sm="5" md="5">
        <v-text-field
          :label="$t('flow.Title')"
          v-model="schema.title"
          outlined
          dense
          :disabled="!!schema.flowID"
        ></v-text-field>
      </v-col>
      <v-col cols>
        <v-text-field
          :label="$t('flow.Description')"
          v-model="schema.description"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols sm="1" md="1">
        <v-btn
          @click="saveFlowDesign"
          color="primary"
          style="margin: 2px 0px 0px -10px; width: 100%"
          :loading="isSavingFlow"
        >
          <v-icon class="mr-2" small>mdi-content-save-outline</v-icon>
          {{ $t("flow.SaveBtn") }}
        </v-btn>
      </v-col>
    </v-row>
    <!-- eslint-disable -->
    <div id="flow-desginer-app" style="height: calc(100vh - 150px)">
      <div class="text-center" v-if="isSkeletonLoading">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
          style="margin-top: calc(50vh - 40px)"
        ></v-progress-circular>
      </div>
      <VueBlocksContainer
        @contextmenu.native="showContextMenu"
        @click.native="closeContextMenu"
        ref="container"
        :blocksContent="schema.blocks"
        :scene.sync="schema.scene"
        class="flow-desginer-container"
        @onBlockEdit="handleBlockEditClick"
        v-on:keyup="handleKeyUpEvent($event)"
      />
      <div
        id="contextMenu"
        ref="contextMenu"
        tabindex="-1"
        v-show="contextMenu.isShow"
        :style="{
          top: contextMenu.top + 'px',
          left: contextMenu.left - 200 + 'px',
        }"
      >
        <template>
          <v-list class="pa-0">
            <v-list-group
              no-action
              active-class
              v-for="(family, ind) in getUniqueValues(
                defaultBlocks.map((b) => b.family)
              )"
              :key="family"
            >
              <template v-slot:activator>
                <v-list-item-title class="font-weight-bold text-capitalize">
                  {{ ind + 1 }}. {{ family.replace(/-/g, " ") }}
                </v-list-item-title>
              </template>
              <v-list-item
                v-for="(block, ind2) in defaultBlocks.filter(
                  (b) => b.family == family
                )"
                :key="block.name"
                @click="addBlockContextMenu(block.name)"
                class="add-block-item pl-4 text-capitalize"
              >
                » {{ ind + 1 }}.{{ ind2 + 1 }} {{ block.title || block.name }}
              </v-list-item>
            </v-list-group>
          </v-list>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import VueBlocksContainer from "@/components/flow/components/VueBlocksContainer";
import domHelper from "@/components/flow/helpers/dom";
import {
  createFlow,
  getAllFlowBlocks,
  getFlowDetail,
  updateFlow,
} from "./services";
import { getUniqueValues } from "@/plugins/helper";
import PopupBlockEditor from "./PopupBlockEditor";
import PopupOutputEditor from "./PopupOutputEditor";

export default {
  name: "App",
  components: {
    VueBlocksContainer,
    PopupBlockEditor,
    PopupOutputEditor,
  },
  data: function () {
    return {
      timeoutProcess: null,
      isSkeletonLoading: true,
      isSavingFlow: false,
      defaultBlocks: [],
      schema: {
        flowID: this.$route.query.flowID,
        title: "",
        description: "",
        blocks: [
          {
            name: "RequestInputs",
            originalName: "RequestInputs",
            title: "Inputs",
            allowAddFields: true,
            fields: [
              {
                name: "path_to_file",
                type: "file",
                attr: "output",
                editable: true,
              },
            ],
          },
          {
            name: "RequestOutputs",
            originalName: "RequestOutputs",
            title: "Outputs",
            allowAddFields: true,
            fields: [
              { name: "predictions", type: "string", attr: "input" },
              { name: "image_save_path", type: "string", attr: "input" },
            ],
          },
        ],
        scene: {
          blocks: [
            {
              id: 0,
              x: -774.3999999999996,
              y: 30.99999999999995,
              name: "RequestInputs",
              title: "Inputs",
              values: {},
              statusID: 1,
            },
            {
              id: 1,
              x: 10.00000000000051,
              y: -6.200000000000965,
              name: "RequestOutputs",
              title: "Outputs",
              values: {},
              statusID: 1,
            },
          ],
          links: [],
          container: { centerX: 1020, centerY: 166, scale: 1.25 },
        },
      },
      selectedBlock: null,
      selectedType: "delay",
      contextMenu: {
        isShow: false,
        mouseX: 0,
        mouseY: 0,
        top: 0,
        left: 0,
      },
      schemaUndoPipes: [],
      schemaUndoPointerIndex: -1,
    };
  },
  computed: {},
  async mounted() {
    await this.getLatestBlocksFromServer();
    if (this.schema.flowID) {
      await this.renderFlowDiagarm();
    }
    this.isSkeletonLoading = false;
  },
  methods: {
    getUniqueValues,
    handleBlockChanged(block) {
      var blockInd = this.schema.blocks.findIndex((b) => b.name == block.name);
      if (blockInd == -1) return;
      var blocks = JSON.parse(JSON.stringify(this.schema.blocks));
      blocks[blockInd] = block;
      this.schema.blocks = blocks;
      this.schema.scene = JSON.parse(JSON.stringify(this.schema.scene));
      this.$forceUpdate();
    },
    handleBlockEditClick({ name }) {
      var blockInd = this.schema.blocks.findIndex((b) => b.name == name);
      if (blockInd == -1) return;
      var block = this.schema.blocks[blockInd];
      if (block.name == "RequestOutputs") {
        this.$refs.PopupOutputEditor.open(block);
      } else {
        this.$refs.PopupBlockEditor.open(block);
      }
    },
    async getLatestBlocksFromServer() {
      var result = await getAllFlowBlocks();
      if (!result || result.error) {
        this.$toast.error("Cannot get all default flow blocks!");
        return;
      }
      this.defaultBlocks = result;
      // add these blocks into the blocks list of schema
      this.schema.blocks = this.schema.blocks.concat(result);
    },
    async renderFlowDiagarm() {
      var { flowID } = this.schema;
      if (!flowID) return;
      var result = await getFlowDetail(flowID);
      if (!result || result.error) {
        this.$toast.error("Cannot get design schema at the moment!");
        return;
      }
      var { blocks, scene, title, description } = result;
      var newBlocks = this.defaultBlocks.filter(
        (b) => !blocks.map((bb) => bb.name).includes(b.name)
      );
      this.schema.blocks = [...newBlocks, ...blocks];
      this.schema.scene = scene;
      this.schema.title = title;
      this.schema.description = description;

      this.$forceUpdate();
    },
    async saveFlowDesign() {
      this.isSavingFlow = true;
      var { flowID } = this.schema;
      var result;
      var body = JSON.parse(JSON.stringify(this.schema));
      if (!flowID) {
        result = await createFlow(body);
      } else {
        result = await updateFlow(flowID, body);
      }
      if (!result || result.error) {
        return this.$toast.error("Cannot save flow design at the moment");
      }
      this.$router.push("/home/flow");
    },
    showContextMenu(e) {
      if (e.preventDefault) e.preventDefault();
      this.contextMenu.isShow = true;
      this.contextMenu.mouseX = e.x;
      this.contextMenu.mouseY = e.y;
      this.$nextTick(function () {
        this.setMenu(e.y, e.x);
        this.$refs.contextMenu.focus();
      });
    },
    setMenu(top, left) {
      let border = 5;
      let contextMenuEl = this.$refs.contextMenu;
      let containerElRect = this.$refs.container.$el.getBoundingClientRect();
      let largestWidth =
        containerElRect.right - contextMenuEl.offsetWidth - border;
      let largestHeight =
        containerElRect.bottom - contextMenuEl.offsetHeight - border;
      if (left > largestWidth) left = largestWidth;
      if (top > largestHeight) top = largestHeight;
      this.contextMenu.top = top;
      this.contextMenu.left = left;
    },
    addBlockContextMenu(name) {
      let offset = domHelper.getOffsetRect(this.$refs.container.$el);
      let x = this.contextMenu.mouseX - offset.left;
      let y = this.contextMenu.mouseY - offset.top;
      this.$refs.container.addNewBlock(name, x, y);
      this.closeContextMenu();
    },
    closeContextMenu() {
      this.contextMenu.isShow = false;
    },
    handleKeyUpEvent(event) {
      console.log(event);
    },
    pushCurrentSchemaIntoUndoPipe() {
      if (this.schemaUndoPipes.length == 10) {
        this.schemaUndoPipes.shift();
        this.schemaUndoPointerIndex -= 1;
      }
      this.schemaUndoPipes.push(this.schema);
      this.schemaUndoPointerIndex += 1;
    },
    undoCurrentSchemaDesign() {
      if (this.schemaUndoPointerIndex == 0) return;
      this.schemaUndoPointerIndex -= 1;
      this.schema = this.schemaUndoPipes[this.schemaUndoPointerIndex];
      this.$forceUpdate();
    },
    redoCurrentSchemaDesign() {
      if (this.schemaUndoPointerIndex == 9) return;
      this.schemaUndoPointerIndex += 1;
      this.schema = this.schemaUndoPipes[this.schemaUndoPointerIndex];
      this.$forceUpdate();
    },
  },
  watch: {
    "schema.scene": {
      handler(newValue) {
        this.pushCurrentSchemaIntoUndoPipe(newValue);
      },
    },
  },
};
</script>

<style lang="scss">
#flow-desginer-app {
  width: calc(100% - 30px);
  height: calc(100%);
  padding: 20px 0 0 20px;
  overflow: hidden;
  display: block;

  .flow-desginer-container {
    width: 100%;
    height: calc(100%);
  }

  .flow-desginer-note-right {
    position: fixed;
    right: 20px;
  }

  #contextMenu {
    position: absolute;
    z-index: 1000;
    background: white;
    border: 1px solid black;
    padding: 0px;
    margin: 0;
  }
}
</style>
