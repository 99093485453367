<template>
  <v-dialog
    @click:outside="closeDialog"
    :value="dialog"
    max-width="650px"
    eager
  >
    <v-card class="pb-2">
      <v-card-title class="text-capitalize">
        Block RequestOutputs
      </v-card-title>
      <v-card-text>
        <v-simple-table
          class="mb-2 block-newfield-table"
          v-if="block.allowAddFields"
        >
          <template v-slot:default>
            <tbody>
              <tr>
                <td class="text-left" style="padding: 0px">
                  <v-select
                    v-model="defaultFields"
                    :items="defaultFieldsOptions"
                    @change="handleChangeDefaultFields"
                    label="Pre-defined Fields"
                    multiple
                    dense
                    outlined
                    hide-details
                    class="float-left mr-5"
                    style="width: 200px"
                  >
                    <!-- eslint-disable-next-line -->
                    <template v-slot:selection="{ item, index }">
                      <span v-if="index === 1" class="black--text text-caption">
                        {{ defaultFields.length }} selected
                      </span>
                    </template>
                  </v-select>
                  <v-text-field
                    v-model="newFieldName"
                    dense
                    outlined
                    hide-details
                    placeholder="New field name..."
                    style="width: 265px"
                    class="float-left"
                  />
                  <v-btn @click="addNewField" class="pl-5 pr-6 ml-4 float-left">
                    <v-icon small class="mr-2">mdi-plus</v-icon>
                    Add
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-simple-table class="block-edit-table">
          <template v-slot:default>
            <thead class="grey lighten-4">
              <tr>
                <th class="text-left">
                  <h3 class="black--text">Field</h3>
                </th>
                <th class="text-left">
                  <h3 class="black--text">Display as</h3>
                </th>
                <th class="text-left">
                  <h3 class="black--text">Nullable</h3>
                </th>
                <th>
                  <h3 class="black--text">Action</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(field, index) in block.fields" :key="index">
                <td>{{ field.name }}</td>
                <td>
                  <span>{{ displayTypes[field.name] || "Plain Text" }}</span>
                </td>
                <td>
                  <span><v-icon>mdi-check</v-icon></span>
                </td>
                <td>
                  <v-btn
                    icon
                    v-if="field.addedByUser"
                    @click="removeField(field.name)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions style="margin-top: -10px">
        <v-spacer></v-spacer>
        <v-btn @click.stop="saveConfig" class="primary mr-2 pl-5 pr-6">
          Save configs
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <DialogConfirm
      ref="DialogConfirm"
      :message="'Cannot add duplicated field!'"
    />
  </v-dialog>
</template>

<script>
import DialogConfirm from "@/components/DialogConfirm";
import { OutputDisplayComponents } from "./constant";

export default {
  components: {
    DialogConfirm,
  },
  computed: {
    defaultFieldsOptions() {
      return Object.keys(OutputDisplayComponents);
    },
  },
  data() {
    return {
      displayTypes: OutputDisplayComponents,
      dialog: false,
      block: {
        fields: [],
      },
      defaultFields: [],
      newFieldName: "",
    };
  },
  methods: {
    handleChangeDefaultFields() {
      var { fields } = this.block;
      var newFields = [];
      fields.forEach((field) => {
        if (field.addedByUser || this.defaultFields.includes(field.name)) {
          newFields.push(field);
        }
      });
      this.block.fields = newFields;
      this.defaultFields.forEach((fieldName) => {
        if (!this.block.fields.map((f) => f.name).includes(fieldName)) {
          this.block.fields.push({
            name: fieldName,
            type: "string",
            attr: "input",
          });
        }
      });
      this.$forceUpdate();
    },
    open(block = {}) {
      // Only open RequestOutputs block
      if (block.name != "RequestOutputs") return;
      this.resetComponent();
      this.block = block;
      var { fields } = this.block;
      this.defaultFields = fields.map((f) => f.name);
      this.dialog = true;
    },
    toggleField(fieldName) {
      var fieldInd = this.block.fields.findIndex((f) => f.name == fieldName);
      if (fieldInd != -1) {
        return this.block.fields.splice(fieldInd, 1);
      }
      var field = {
        name: fieldName,
        type: "string",
        attr: "input",
      };
      this.block.fields.push(field);
    },
    async addNewField() {
      if (this.block.fields.find((f) => f.name == this.newFieldName)) {
        return await this.$refs.DialogConfirm.confirm();
      }
      var field = {
        name: this.newFieldName,
        type: "string",
        attr: "input",
        addedByUser: true,
      };
      this.block.fields.push(field);
      this.newFieldName = "";
    },
    removeField(fieldname) {
      var ind = this.block.fields.findIndex((f) => f.name == fieldname);
      if (ind == -1) return;
      this.block.fields.splice(ind, 1);
    },
    async saveConfig() {
      this.$emit("onBlockEdit", this.block);
      this.closeDialog();
    },
    resetComponent() {
      this.newFieldName = "";
      this.block = {
        fields: [],
      };
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss">
.h-4 {
  height: 40px !important;
}
.lh-4 {
  line-height: 40px !important;
}
.block-edit-table {
  border: 1px solid #e0e0e0;
  th {
    border-right: 1px solid #e0e0e0;
    font-size: 0.85rem !important;
  }
  td {
    border-right: 1px solid #e0e0e0;
    font-size: 0.95rem !important;
  }
  th:last-child {
    border-right: none;
  }
  td:last-child {
    border-right: none;
  }
}
.block-newfield-table {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>